import React from 'react';

const ServicesScalableImplementations = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.5 33H2.03519C1.49542 33 0.977758 32.787 0.59608 32.4074C0.214403 32.0279 0 31.5131 0 30.9763V11.0237C0 10.4869 0.214403 9.97208 0.59608 9.59255C0.977758 9.21302 1.49542 9 2.03519 9H32.9648C33.5046 9 34.0222 9.21302 34.4039 9.59255C34.7856 9.97208 35 10.4869 35 11.0237V16.7244C35 16.9134 34.9244 17.0946 34.7901 17.2283C34.6557 17.3619 34.4734 17.437 34.2834 17.437C34.0933 17.437 33.911 17.3619 33.7766 17.2283C33.6422 17.0946 33.5667 16.9134 33.5667 16.7244V11.0237C33.5667 10.9451 33.5512 10.8673 33.5209 10.7947C33.4907 10.7221 33.4463 10.6561 33.3904 10.6006C33.3345 10.545 33.2682 10.5009 33.1951 10.4708C33.1221 10.4407 33.0439 10.4252 32.9648 10.4252H2.03519C1.95614 10.4252 1.87781 10.4407 1.80477 10.4708C1.73174 10.5009 1.66542 10.545 1.60952 10.6006C1.55362 10.6561 1.50926 10.7221 1.479 10.7947C1.44875 10.8673 1.43325 10.9451 1.43325 11.0237V30.9763C1.43325 31.1351 1.49663 31.2875 1.60952 31.3998C1.72241 31.512 1.87554 31.5748 2.03519 31.5748H24.5C24.6878 31.5819 24.866 31.6594 24.9989 31.7916C25.1318 31.9237 25.2095 32.1007 25.2166 32.2874C25.2166 32.4764 25.1412 32.6579 25.0068 32.7916C24.8724 32.9252 24.6901 33 24.5 33Z"
      fill="#FCFCFD"
    />
    <path
      d="M24.0016 28.1521L0.71586 28C0.526002 28 0.343887 27.9473 0.209637 27.8535C0.0753874 27.7597 0 27.6326 0 27.5C0 27.3674 0.0753874 27.24 0.209637 27.1462C0.343887 27.0525 0.526002 27 0.71586 27L24.0016 27.1521C24.1915 27.1521 24.3736 27.2046 24.5079 27.2983C24.6421 27.3921 24.7175 27.5195 24.7175 27.6521C24.7104 27.7831 24.6327 27.9073 24.5 28C24.3673 28.0927 24.1892 28.1471 24.0016 28.1521Z"
      fill="#FCFCFD"
    />
    <path
      d="M18.3478 13H16.6522C16.4792 13 16.3133 12.8946 16.191 12.707C16.0687 12.5195 16 12.2652 16 12C16 11.7348 16.0687 11.4805 16.191 11.293C16.3133 11.1054 16.4792 11 16.6522 11H18.3478C18.5208 11 18.6866 11.1054 18.8089 11.293C18.9312 11.4805 19 11.7348 19 12C19 12.2652 18.9312 12.5195 18.8089 12.707C18.6866 12.8946 18.5208 13 18.3478 13Z"
      fill="#FCFCFD"
    />
    <path
      d="M24.1898 39.9949H9.72332C9.53149 39.9949 9.34747 39.9201 9.21182 39.7872C9.07617 39.6543 9 39.4741 9 39.2861V38.2656C9.00458 38.1006 9.06779 37.9423 9.17871 37.8181C9.28963 37.6939 9.44128 37.6115 9.60757 37.5852L13.5425 36.8481H14.2369V32.7087C14.244 32.523 14.3225 32.347 14.4566 32.2156C14.5908 32.0842 14.7706 32.007 14.9602 32H19.1265C19.3184 32 19.5024 32.0747 19.638 32.2076C19.7737 32.3406 19.8499 32.5208 19.8499 32.7087V36.8481H20.631L24.4212 37.5852C24.5847 37.6122 24.7329 37.6957 24.8391 37.8205C24.9452 37.9453 25.0022 38.1031 24.9999 38.2656V39.2861C25.0007 39.3868 24.9795 39.4865 24.9379 39.5785C24.8962 39.6706 24.835 39.7527 24.7584 39.8197C24.6817 39.8868 24.5914 39.9374 24.4934 39.9675C24.3953 39.9977 24.2918 40.0069 24.1898 39.9949ZM11.8643 38.5774H22.0198L20.2838 38.2372H19.0108C18.824 38.2299 18.6474 38.152 18.5179 38.0198C18.3885 37.8877 18.3163 37.7116 18.3164 37.5284V33.3891H15.5678V37.4433C15.5678 37.6313 15.4916 37.8115 15.3559 37.9444C15.2203 38.0773 15.0363 38.152 14.8444 38.152H13.6293L11.8643 38.5774Z"
      fill="#FCFCFD"
    />
    <path
      d="M42.4949 37H41.5051C41.3711 37 41.2426 36.9473 41.1479 36.8535C41.0532 36.7597 41 36.6326 41 36.5C41 36.3674 41.0532 36.2403 41.1479 36.1465C41.2426 36.0527 41.3711 36 41.5051 36H42.4949C42.6289 36 42.7574 36.0527 42.8521 36.1465C42.9468 36.2403 43 36.3674 43 36.5C43 36.6326 42.9468 36.7597 42.8521 36.8535C42.7574 36.9473 42.6289 37 42.4949 37Z"
      fill="#FCFCFD"
    />
    <path
      d="M34.3478 36H32.6522C32.4792 36 32.3133 35.9473 32.191 35.8535C32.0687 35.7597 32 35.6326 32 35.5C32 35.3674 32.0687 35.2403 32.191 35.1465C32.3133 35.0527 32.4792 35 32.6522 35H34.3478C34.5208 35 34.6866 35.0527 34.8089 35.1465C34.9312 35.2403 35 35.3674 35 35.5C35 35.6326 34.9312 35.7597 34.8089 35.8535C34.6866 35.9473 34.5208 36 34.3478 36Z"
      fill="#4DE0D1"
    />
    <path
      d="M40.8954 40H26.0754C25.5249 40 24.9971 39.7835 24.6078 39.3985C24.2186 39.0135 24 38.4913 24 37.9468V18.0534C24 17.5089 24.2186 16.9867 24.6078 16.6017C24.9971 16.2167 25.5249 16.0002 26.0754 16.0002H40.8954C41.1704 15.9964 41.4434 16.0466 41.6986 16.1481C41.9538 16.2496 42.186 16.4004 42.3818 16.5914C42.5776 16.7824 42.7331 17.01 42.8393 17.261C42.9454 17.512 43 17.7814 43 18.0534V24.3568C43 24.5485 42.923 24.7323 42.786 24.8679C42.649 25.0034 42.463 25.0796 42.2692 25.0796C42.0754 25.0796 41.8895 25.0034 41.7525 24.8679C41.6154 24.7323 41.5385 24.5485 41.5385 24.3568V18.0534C41.5309 17.8898 41.4599 17.7352 41.3401 17.6221C41.2203 17.509 41.061 17.4458 40.8954 17.446H26.0754C25.9126 17.446 25.7564 17.51 25.6413 17.6239C25.5262 17.7377 25.4615 17.8924 25.4615 18.0534V37.9468C25.4615 38.1078 25.5262 38.2625 25.6413 38.3763C25.7564 38.4902 25.9126 38.5542 26.0754 38.5542H40.8954C41.0892 38.5542 41.2751 38.6305 41.4121 38.766C41.5492 38.9016 41.6262 39.0854 41.6262 39.2771C41.6262 39.4688 41.5492 39.6527 41.4121 39.7882C41.2751 39.9238 41.0892 40 40.8954 40Z"
      fill="#4DE0D1"
    />
    <path
      d="M33.5266 20.9998C33.2277 21.0051 32.934 20.9209 32.6833 20.7581C32.4326 20.5954 32.2362 20.3613 32.1193 20.0862C32.0025 19.811 31.9705 19.5073 32.0275 19.2138C32.0846 18.9203 32.228 18.6509 32.4394 18.4395C32.6507 18.2281 32.9204 18.0845 33.2139 18.0275C33.5073 17.9705 33.8111 18.0026 34.0863 18.1194C34.3614 18.2362 34.5954 18.4327 34.7582 18.6834C34.921 18.9342 35.0051 19.2278 34.9998 19.5267C34.9929 19.9153 34.8355 20.2858 34.5607 20.5606C34.2859 20.8354 33.9151 20.9929 33.5266 20.9998ZM33.5266 19.3658C33.5044 19.3607 33.4813 19.3612 33.4595 19.3675C33.4376 19.3737 33.4177 19.3857 33.4016 19.4018C33.3855 19.4179 33.3738 19.4375 33.3676 19.4594C33.3613 19.4812 33.3607 19.5046 33.3659 19.5267C33.3659 19.6874 33.6605 19.6874 33.6605 19.5267C33.6605 19.366 33.6069 19.3658 33.5266 19.3658Z"
      fill="#4DE0D1"
    />
    <path
      d="M46.226 40H37.774C37.5687 40 37.3718 39.9255 37.2267 39.793C37.0815 39.6604 37 39.4807 37 39.2933V24.7067C37 24.5193 37.0815 24.3396 37.2267 24.207C37.3718 24.0745 37.5687 24 37.774 24H46.226C46.4313 24 46.6282 24.0745 46.7733 24.207C46.9185 24.3396 47 24.5193 47 24.7067V39.2933C47 39.4807 46.9185 39.6604 46.7733 39.793C46.6282 39.9255 46.4313 40 46.226 40ZM38.548 38.5866H45.483V25.4134H38.548V38.5866Z"
      fill="#FCFCFD"
    />
  </svg>
);

export default ServicesScalableImplementations;
